<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add/Edit Location Address</h1>
    </page-header>

    <page-body class>
      <address-form
        v-if='translations.components["address-form"].tcSelectAType'
        :locationName="orgName"
        :locationKey="userSelectedLocationKey"
        :address="userSelectedAddress"
        :i18n="translations.components"
      ></address-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import addressForm from '@/components/AddressForm.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-edit-location-address',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'address-form': {},
        },
      },
      orgName: null,
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
  },
  async created () {
    try {
      this.setLoadingStatus(true)
      this.orgName = this.locationDetail.org_name
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('address-form')
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      locationDetail: 'scriptureDistribution/locationDetail',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedAddress: 'user/userSelectedAddress',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
    }),
  },
  components: {
    addressForm: addressForm,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style scoped>
.page-body {
  padding: 80px 15px;
}
</style>
